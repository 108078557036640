import api from '@/utils/api';
import registerServer from '@/utils/registerServer';
import request from '@/utils/request';

const { loginSSO, logoutSSO } = api;

const methods = {
  loginSSO: {
    url: loginSSO,
    method: 'post',
  },
  logoutSSO: {
    url: logoutSSO,
    method: 'post',
  },
} as const;

const ssoService = registerServer<keyof typeof methods>(methods, request);

export default ssoService;
